import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import CurriculumModal from "./Modal";
import CourseDetailsModal from "./Modal2";
import ModalForm from './Modal3';
import logo from '../img/logo_color-removebg-preview.png';
import '../components/Navbar.css';


function NavigationBar() { 
  const [modalShow, setModalShow] = useState(false);
  const [courseDetailsModalShow, setCourseDetailsModalShow] = useState(false);
  const [modalFormShow,setModalFormShow] = useState(false);

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleCourseDetailsModalClose = () => setCourseDetailsModalShow(false);
  const handleCourseDetailsModalShow = () => setCourseDetailsModalShow(true);

  const handleModalFormClose =()=> setModalFormShow(false);
  const handleModalFormShow =()=> setModalFormShow(true);

  
 
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="fixed-top"
        expanded={expanded}
        onToggle={(isExpanded) => setExpanded(isExpanded)}
        style={{ fontFamily: "Margarine, calibri" }}
      >
        <Container>
          <Navbar.Brand href="#">
            <img className="brand-logo-img" src={logo} alt="logo" style={{ width: '7rem' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              {/* <Nav.Link herf="#">About</Nav.Link> */}
              {/* <Nav.Link herf="#">Contact</Nav.Link> */}
              {/* <Nav.Link herf="#">Team</Nav.Link> */}
              {/* <Nav.Link as={Link} to="/privacy">Privacy</Nav.Link> */}
              <Nav.Link as={Link} to="faq">FAQs</Nav.Link>
              <NavDropdown title="Download" id="basic-nav-dropdown" style={{ fontFamily: "Margarine, calibri" }}>
                <NavDropdown.Item onClick={handleModalShow}>Program Brochure</NavDropdown.Item>
                <NavDropdown.Item onClick={handleCourseDetailsModalShow}>Program Details</NavDropdown.Item>             
              </NavDropdown>              
            </Nav>
            <span>
            <button onClick={handleModalFormShow} className='book-call-btn'>Book 1:1 Career Acceleration Session</button>

            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>     
      <CurriculumModal show={modalShow} handleClose={handleModalClose} />
      <CourseDetailsModal show={courseDetailsModalShow} handleClose={handleCourseDetailsModalClose} />
      <ModalForm show={modalFormShow} handleClose={handleModalFormClose} />

    </>
  );
}

export default NavigationBar;